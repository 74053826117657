<template>
    <div class="Plan">
        <div style="display: flex">
<!--            <div style="margin-top: 106px">-->
<!--                <div class="list" :class="active == index ? 'list1' : ''" @click="changeMenu(index)" v-for="(item,index) in list" :key="index"><div class="name">{{item}}</div></div>-->
<!--            </div>-->
            <div>
            </div>
            <div class="PlanRight">
                <div class="pie">
                    <div class="label"><div>公路</div></div>
                    <div class="pieWrap">
                        <div class="echarts" id="pie" @click="changeEchart(true)"></div>
                        <div class="echarts" id="pie1" @click="changeEchart(false)"></div>
                    </div>

                    <div class="smallechart">
                        <div class="small" v-if="showLong">
                            <div class="echarts" id="line1" ></div>
                            <div class="echarts" id="line2" ></div>
                            <div class="echarts" id="line3" ></div>
                        </div>
                        <div class="small" v-else>
                            <div class="echarts" id="line4" ></div>
                            <div class="echarts" id="line5" ></div>
                            <div class="echarts" id="line6" ></div>
                        </div>
                    </div>
                </div>
<!--               <div class="echart">-->
<!--                   <div class="label"><div>公路</div></div>-->
<!--                 <div>-->
<!--                     <div class="echarts" id="echarts1" ></div>-->
<!--                 </div>-->
<!--               </div>-->
                <el-carousel indicator-position="outside" style="width: 100%" :interval="5000">
                    <el-carousel-item style="display: flex;justify-content: space-between">
                        <div class="echart">
                            <div class="label"><div>水运</div></div>
                            <div>
                                <div class="echarts" id="echarts5" ></div>
                            </div>
                        </div>
                        <div class="echart">
                            <div class="label"><div>铁路</div></div>
                            <div>
                                <div class="echarts" id="echarts3" ></div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item style="display: flex;justify-content: space-between">
                        <div class="echart">
                            <div class="label"><div>民航</div></div>
                            <div>
                                <div class="echarts" id="echarts4"></div>
                            </div>
                        </div>
                        <div class="echart">
                            <div class="label"><div>运输</div></div>
                            <div>
                                <div class="echarts" id="echarts6" ></div>
                            </div>
                        </div>
                    </el-carousel-item>
<!--                    <el-carousel-item>-->
<!--                        <div class="echart">-->
<!--                            <div class="label"><div>危桥</div></div>-->
<!--                            <div>-->
<!--                                <div class="echarts" id="echarts2" ></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </el-carousel-item>-->
                </el-carousel>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Plan",
        data(){
            return{
                showLong: true,
                list:['十四五项目库统计','双碳统计'],
                active:0,
                roadYear:[],
                bridgeYear:[],
                allGs:0,
                allGd:0,
                allSd:0,
                alltz:0,
                allGszj:0,
                allGdzj:0,
                allSdzj:0,
                gsPieData:[],
                gsPie1Data:[]
            }
        },
        mounted(){
            var that = this
            this.allGs = 0
            this.allGd = 0
            this.allSd = 0
            this.http.post('/api/Plan_road/PlanStatisticsSummary',{}).then(res=>{
                console.log(res)
                var year = []
                var bridgeYear = []
                var tlYear = []
                var mhYear = []
                var syYear = []
                var ysYear = []
                // res['公路'] = res['公路'].filter((q)=>{
                //     return q.ND == '2021' ||  q.ND == '2022' || q.ND == '2023' ||  q.ND == '2024' ||  q.ND == '2025'
                // })
                res['公路'].map((item,index)=>{
                    if (item.ND != '' && item.ND != null){
                        year.push(item.ND)
                    }
                })
                const map = new Map()
                this.roadYear= year.filter(key => !map.has(key) && map.set(key, 1))
                console.log(that.bridgeYear)
                this.gs =new Array(this.roadYear.length).fill(0);
                this.gszj =new Array(this.roadYear.length).fill(0);
                this.gd =new Array(this.roadYear.length).fill(0);
                this.gdzj =new Array(this.roadYear.length).fill(0);
                this.sd = new Array(this.roadYear.length).fill(0);
                this.sdzj = new Array(this.roadYear.length).fill(0);
                this.gltz = new Array(this.roadYear.length).fill(0);
                    this.roadYear.map((item,index)=>{
                        var tz = 0
                        res['公路'].map((subItem,subIndex)=>{
                            if (subItem.ND != '' && subItem.ND != null){
                                if (subItem.ND == item){
                                    tz += subItem.ZTZ
                                    this.gltz.splice(index,1,parseFloat(tz.toFixed(2)))
                                }
                                switch (subItem.GLLX) {
                                    case '高速公路':
                                        if(subItem.ND == item){
                                            console.log(subItem)
                                            this.gs.splice(index,1,subItem.JSGM)
                                            this.gszj.splice(index,1,subItem.ZTZ)
                                        }
                                        break;
                                    case '普通国道':
                                        if(subItem.ND == item){
                                            this.gd.splice(index,1,subItem.JSGM)
                                            this.gdzj.splice(index,1,subItem.ZTZ)
                                        }
                                        break;
                                    case '普通省道':
                                        if(subItem.ND == item){
                                            this.sd.splice(index,1,subItem.JSGM)
                                            this.sdzj.splice(index,1,subItem.ZTZ)
                                        }
                                        break;
                                }
                            }
                        })
                    })
                    this.gs.map((item,index)=>{
                        this.allGs += item
                    })
                this.gszj.map((item,index)=>{
                    console.log(item)
                    this.allGszj += item
                })
                    this.gd.map((item,index)=>{
                        this.allGd += item
                    })
                this.gdzj.map((item,index)=>{
                    this.allGdzj += item
                })
                    this.sd.map((item,index)=>{
                        this.allSd += item
                    })
                this.sdzj.map((item,index)=>{
                    this.allSdzj += item
                })
                this.gltz.map((item,index)=>{
                    this.alltz += item
                })
                console.log(this.gszj, this.allGszj,' this.allGszj')

                this.gsPieData = [{name:'高速',value:this.allGs.toFixed(2)},{name:'国道',value:this.allGd.toFixed(2)},{name:'省道',value:this.allSd.toFixed(2)}]
                this.gsPie1Data = [{name:'高速',value:this.allGszj.toFixed(2)},{name:'国道',value:parseFloat(this.allGdzj.toFixed(2))},{name:'省道',value:parseFloat(this.allSdzj.toFixed(2))}]

                res['危桥'].map((item,index)=>{
                    if (item.ND != '' && item.ND != null){
                        bridgeYear.push(item.ND)
                    }
                })
                this.bridgeYear = bridgeYear
                this.wqgm = new Array(this.bridgeYear.length).fill(0);
                this.wqtz = new Array(this.bridgeYear.length).fill(0);
                this.bridgeYear.map((item,index)=>{
                    res['危桥'].map((subItem,subIndex)=>{
                        if (subItem.ND != '' && subItem.ND != null){
                            if (subItem.ND == item){
                                this.wqtz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                                this.wqgm.splice(index,1,subItem.JSGM)
                            }
                        }
                    })
                })
                res['铁路'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        tlYear.push(item.XMXZ)
                    }
                })
                this.tlYear = tlYear
                this.tlgm = new Array(this.tlYear.length).fill(0);
                this.tltz = new Array(this.tlYear.length).fill(0);
                this.tlYear.map((item,index)=>{
                    res['铁路'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.tltz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                                this.tlgm.splice(index,1,subItem.JSGM)
                            }
                        }
                    })
                })


                res['民航'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        mhYear.push(item.XMXZ)
                    }
                })
                this.mhYear = mhYear
                this.mhtz = new Array(this.mhYear.length).fill(0);
                this.mhYear.map((item,index)=>{
                    res['民航'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.mhtz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                            }
                        }
                    })
                })




                res['水运'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        syYear.push(item.XMXZ)
                    }
                })
                this.syYear = syYear
                this.sytz = new Array(this.syYear.length).fill(0);
                this.syYear.map((item,index)=>{
                    res['水运'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.sytz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                            }
                        }
                    })
                })


                res['运输'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        ysYear.push(item.XMXZ)
                    }
                })
                this.ysYear = ysYear
                this.ystz = new Array(this.ysYear.length).fill(0);
                this.ysYear.map((item,index)=>{
                    res['运输'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.ystz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                            }
                        }
                    })
                })
                console.log(this.gs)
                this.getPie()
                this.getPie1()
                this.getline1('line1',this.gs,'高速','#0070FB')
                this.getline1('line2',this.gd,'国道','#1ED8F4')
                this.getline1('line3',this.sd,'省道','#FF5C5C')
                // this.getEcharts1()
                // this.getEcharts2()
                this.getEcharts3()
                this.getEcharts4()
                this.getEcharts5()
                this.getEcharts6()
                // this.getline2('line4',this.gszj,'高速','#0070FB')
                // this.getline2('line5',this.sdzj,'国道','#1ED8F4')
                // this.getline2('line6',this.sdzj,'省道','#FF5C5C')
            })
            window.addEventListener("resize", () => {
                this.myChartpie1.resize();
                this.myChart1.resize();
                // this.myChart2.resize();
                this.myChart3.resize();
                this.myChart4.resize();
                this.myChart5.resize();
                this.myChart6.resize();
                this.line1.resize()
                this.line2.resize()
                this.line3.resize()
                this.line4.resize()
                this.line5.resize()
                this.line6.resize()
            });
        },
        methods:{
            changeEchart(val){
                this.showLong = val
                if (val){
                    setTimeout(()=>{
                        this.getline1('line1',this.gs,'高速','#0070FB')
                        this.getline1('line2',this.gd,'国道','#1ED8F4')
                        this.getline1('line3',this.sd,'省道','#FF5C5C')
                    },50)
                }else{
                    setTimeout(()=>{
                        this.getline2('line4',this.gszj,'高速','#0070FB')
                        this.getline2('line5',this.sdzj,'国道','#1ED8F4')
                        this.getline2('line6',this.sdzj,'省道','#FF5C5C')
                    },50)

                }
            },
            eConsole(param) {
                //alert(option.series[0].data.length);
                //alert(option.series[0].data[i]);
                //param.dataIndex 获取当前点击索引，
                //alert(param.dataIndex);
                this.clickFunc(param.dataIndex);//执行点击效果
            },
            clickFunc(e){
              console.log(e)
            },
            changeMenu(index){
                if(index != 0){
                    this.$message.warning('此功能暂未开放,敬请期待')
                    return false
                }
                this.active = index

            },
            //公路
            getPie() {
                var that = this
                this.myChart1 = this.$echarts.init(
                    document.getElementById("pie")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.allGs+that.allGd+that.allSd).toFixed(2),
                        subtext: '总里程(公里)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                    }
                                },
                                formatter: "{value|{c}}公里",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: that.gsPieData
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);
                this.myChart1.on("click", this.eConsole);
            },
            getPie1() {
                var that = this
                this.myChartpie1 = this.$echarts.init(
                    document.getElementById("pie1")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.allGszj+that.allGdzj+that.allSdzj).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:10,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: that.gsPie1Data
                        }
                    ]
                };
                this.myChartpie1.setOption(pieoption);
                this.myChartpie1.on("click", this.eConsole);
            },
            getline1(name,data,yValue,color) {
                this[name] = this.$echarts.init(
                    document.getElementById(name)
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        },
                        formatter: '{a} <br/>{b}:{c}公里'
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: [yValue],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        },
                        top:30,
                        itemGap: 23,
                    },
                    grid: {
                        top: '30%',  // 组件离容器上侧的距离,百分比字符串或整型数字
                        left: '5%',    // 组件离容器左侧的距离,百分比字符串或整型数字
                        right: '5%',
                        bottom: '10%',
                        containLabel: true //grid 区域是否包含坐标轴的刻度标签，
                    },
                    // dataZoom: [{
                    //     type: 'slider',
                    //     show: true,
                    //     xAxisIndex: [0],
                    //     start: 0,
                    //     end: 50 //初始化滚动条
                    // }],
                    xAxis: [
                        {
                            type: 'category',
                            data: this.roadYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',
                                fontSize:12,
                            },

                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（公里）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:12,
                                }
                            },
                        },
                    ],
                    series: [
                        {
                            name: yValue,
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:color,
                                }
                            },
                            data: data
                        },
                    ]
                };
                this[name].setOption(pieoption);

            },
            getline2(name,data,yValue,color) {
                this[name] = this.$echarts.init(
                    document.getElementById(name)
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        },
                        formatter: '{a} <br/>{b}年:{c}亿元'
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: [yValue],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        },
                        top:30,
                        itemGap: 23,
                    },
                    grid: {
                        top: '30%',  // 组件离容器上侧的距离,百分比字符串或整型数字
                        left: '5%',    // 组件离容器左侧的距离,百分比字符串或整型数字
                        right: '5%',
                        bottom: '10%',
                        containLabel: true //grid 区域是否包含坐标轴的刻度标签，
                    },
                    // dataZoom: [{
                    //     type: 'slider',
                    //     show: true,
                    //     xAxisIndex: [0],
                    //     start: 0,
                    //     end: 50 //初始化滚动条
                    // }],
                    xAxis: [
                        {
                            type: 'category',
                            data: this.roadYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '总投资（亿元）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14,
                                }
                            },
                        },
                    ],
                    series: [
                        {
                            name: yValue,
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:color,
                                }
                            },
                            data: data
                        },
                    ]
                };
                this[name].setOption(pieoption);

            },
            getEcharts1() {
                 this.myChart1 = this.$echarts.init(
                    document.getElementById("echarts1")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                       show:false
                    },
                    legend: {
                        data: ['高速', '普通国道', '普通省道'],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        }
                    },
                    // dataZoom: [{
                    //     type: 'slider',
                    //     show: true,
                    //     xAxisIndex: [0],
                    //     start: 0,
                    //     end: 50 //初始化滚动条
                    // }],
                    xAxis: [
                        {
                            type: 'category',
                            data: this.roadYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（公里）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14,
                                }
                            }
                        },
                        {
                            type: 'value',
                            name: '投资金额（亿元）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value} '
                            }
                        }
                    ],
                    series: [
                        {
                            name: '高速',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            // label: {
                            //     show: true,
                            //     color:'#9FDAFC',
                            //     fontSize:'12px',
                            //     position: 'top'
                            // },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:'#1ED8F4'
                                }
                            },
                            data: this.gs
                        },
                        {
                            name: '普通国道',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            // label: {
                            //     show: true,
                            //     color:'#9FDAFC',
                            //     fontSize:'12px',
                            //     position: 'top'
                            // },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:'#FF5C5C'
                                }
                            },
                            data:  this.gd
                        },
                        {
                            name: '普通省道',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            // label: {
                            //     show: true,
                            //     color:'#9FDAFC',
                            //     fontSize:'12px',
                            //     position: 'top'
                            // },
                            data:  this.sd,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:'#0070FB'
                                }
                            },
                        },
                        {
                            name: '总投资金额',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top',
                            },
                            itemStyle:{
                                normal:{
                                    color:'#FDB628'
                                }
                            },
                            data: this.gltz
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);

            },
            //危桥
            getEcharts2() {
                var that = this
                this.myChart2 = this.$echarts.init(
                    document.getElementById("echarts2")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },

                    xAxis: [
                        {
                            type: 'category',
                            data: that.bridgeYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（延米）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            }
                        },
                        {
                            type: 'value',
                            name: '投资金额（万元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value} '
                            }
                        }
                    ],
                    series: [
                        {
                            name: '建设规模',
                            type: 'bar',
                            barWidth : 30,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new that.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#28D8E8'},
                                            {offset: 1, color: '#0489F0'}
                                        ])
                                    }
                                }
                            },
                            data: that.wqgm
                        },
                        {
                            name: '投资金额',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '万元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#FDB628'
                                }
                            },
                            data: that.wqtz
                        }
                    ]
                };
                this.myChart2.setOption(pieoption);
            },
            //铁路
            getEcharts3() {
                var that = this
                this.myChart3 = this.$echarts.init(
                    document.getElementById("echarts3")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: this.tlYear,
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:14
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data:  this.tlYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（公里）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },

                        },
                        {
                            type: 'value',
                            name: '投资金额（亿元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value} '
                            }
                        }
                    ],
                    series: [
                        {
                            name: '建设规模',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new that.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#28D8E8'},
                                            {offset: 1, color: '#0489F0'}
                                        ])
                                    }
                                }
                            },
                            data: this.tlgm
                        },
                        {
                            name: '投资金额',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '万元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#FDB628'
                                }
                            },
                            data: that.tltz
                        }
                    ]
                };
                this.myChart3.setOption(pieoption);
            },
            //民航
            getEcharts4() {
                this.myChart4 = this.$echarts.init(
                    document.getElementById("echarts4")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.mhYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '投资金额（亿元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '投资金额',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#FEE6B6'},
                                            {offset: 1, color: '#FDB628'}
                                        ])
                                    }
                                }
                            },
                            data: this.mhtz
                        }
                    ]
                };
                this.myChart4.setOption(pieoption);
            },
            //水运
            getEcharts5() {
                this.myChart5 = this.$echarts.init(
                    document.getElementById("echarts5")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.syYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '投资金额（亿元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '投资金额',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#FEE6B6'},
                                            {offset: 1, color: '#FDB628'}
                                        ])
                                    }
                                }
                            },
                            data:this.sytz
                        }
                    ]
                };
                this.myChart5.setOption(pieoption);
            },
            //运输
            getEcharts6() {
                this.myChart6 = this.$echarts.init(
                    document.getElementById("echarts6")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.ysYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '投资金额（亿元）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value}'
                            }
                        }
                    ],
                    series: [
                        {
                            name: '投资金额',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '万元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top',
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#FEE6B6'},
                                            {offset: 1, color: '#FDB628'}
                                        ])
                                    }
                                }
                            },
                            data: this.ystz
                        }
                    ]
                };
                this.myChart6.setOption(pieoption);
            },
        }
    }
</script>

<style scoped lang="less">
    .list{
        cursor: pointer;
        line-height: 88px;
        width: 339px;
        height: 88px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        background: url("../../assets/images/statistics/bg-m.png");
        background-size: 339px 88px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .name{
            text-align: center;
            width: 250px;
        }
    }
    .list1{
        background: url("../../assets/images/statistics/bg-m-active.png");
        background-size: 339px 88px;
    }
    .PlanRight{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 50px 68px;
        width: 100%;
        height: 80vh;
        background: url("../../assets/images/statistics/bg-ghgl-r.png") ;
        background-size: 100% 100%;
        .echart{
            width:49%;
            height: 25vh;
            background: rgba(0,31,103,0.4500);
            margin-bottom: 10px;
        }
        .pie{
            width: 100%;
            min-height: 26.8vh;
            background: rgba(0,31,103,0.4500);
            margin-bottom: 10px;
            .pieWrap{
                min-height: 25vh;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                .echarts{
                    width: 100%;
                    height: 25vh;
                }
            }
        }
        .label{
            margin-top: 20px;
            color: #fff;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 38px;
            width: 133px;
            height: 40px;
            background: url("../../assets/images/statistics/bg-tit.png");
            div{
                margin-left: 23px;
            }
        }
    }
    .small{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .echarts{
            width: 30%;
            height: 25vh;
        }
    }

    .echarts{
        width: auto;
        height: 25vh;
    }
    ::v-deep .el-carousel__arrow{
        background: rgba(255,255,255,.5);
    }
</style>
